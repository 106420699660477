import { UserPreferencesService } from './../../services/user-preferences.service';
import { SecurityService } from './../../services/security/security.service';
import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { ProcessingActivityLogService } from './../../services/processing-activity-log.service';
import { Filter, SearchFilters } from './../../data/class';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { AppDataService } from './../../app-data.service';
import { IntegrationsService } from './../../services/integrations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { Integration } from 'src/app/data/model';
import {
  faExchangeAlt,
  faCheckSquare,
  faSpinner,
  faCalendar,
  faTimes,
  faFolderOpen,
  faFile,
  faCog,
  faSyncAlt,
  faCopy,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { GlobalEventService } from 'src/app/services/global-event.service';
import { SearchFilterComponent } from '../../search-filter/search-filter/search-filter.component';

@Component({
  selector: 'app-integration-stage-load',
  templateUrl: './integration-stage-load.component.html',
  styleUrls: ['./integration-stage-load.component.scss']
})
export class IntegrationStageLoadComponent implements OnInit {

  selectedIntegrationId: number;
  integrationType: string;
  integration: Integration;
  selectedIntegrationName: string;
  lockedTables = false;
  lock = false;
  lockRaw = false;
  lockedTablesRaw = false;
  isLoading = false;
  selectedCustomers: any[] = [];
  filteredLoadStatuses: any[] = [];
  select: any = {};
  filter = new Filter();
  filterRaw = new Filter();
  limit = 20;
  statuses: any;
  customers: any[] = [];
  loads: any[] = [];
  lengthToCompare: number;
  filteredLoadTypes: any[] = [];
  selectedLoad: any;
  processingLogs: any;
  fromDateValue: number;
  toDateValue: number;
  faExchangeAlt = faExchangeAlt;
  faCheckSquare = faCheckSquare;
  faSyncAlt = faSyncAlt;
  faSpinner = faSpinner;
  faCalendar = faCalendar;
  faCopy = faCopy;
  faTimes = faTimes;
  faFolderOpen = faFolderOpen;
  faFileImport = faFileImport;
  allowedAdvanced: any;
  faCog = faCog;
  searchFilters: SearchFilters;
  searchFiltersRaw: SearchFilters;
  integrationId: number;
  showSearch = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchFiltersRowChild') searchFiltersRowChild: SearchFilterComponent;

  constructor(
    private titleHelper: Title,
    private route: ActivatedRoute,
    private router: Router,
    private integrationsService: IntegrationsService,
    private globalData: AppDataService,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private processingActivityLogService: ProcessingActivityLogService,
    private responseHelperService: ResponseHelperService,
    private securityService: SecurityService,
    private userPreferencesService: UserPreferencesService,
    private globalEventService: GlobalEventService,
    private toastr: ToastrService
  ) { }

  statusHelper = this.statusHelperService.getStatus('badge');
  isProgramManager = this.globalData.isProgramManager;
  isAdmin = this.globalData.isAdmin;
  convertImportStatus = this.statusHelperService.convertImportStatus;

  numColumns = this.isAdmin ? 7 : 6;

  ngOnInit(): void {
    this.getAllCustomers();
    this.searchFilters = {
      formName: 'integration-stage-load-search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('IMPORT'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
        {
          name: 'customer',
          type: 'array',
          placeholder: 'Customers...',
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'select',
          multiple: true,
          choices: this.customers
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
      onClear: () => { this.searchFiltersRowChild.searchFilters.clearAll(); }
    };

    this.titleHelper.setTitle('File Loads');
    this.allowedAdvanced =
      typeof this.securityService.allowAdvancedIntegrationFeatures ===
      'function'
        ? this.securityService.allowAdvancedIntegrationFeatures
        : this.isProgramManager;
    this.initFilter();
    this.setStatuses();
    this.integrationId = +this.route.snapshot.paramMap.get('id');
  }

  initNewSearch(): void {
    this.initFilter();
    if (this.integrationId) {
      this.getIntegration(this.integrationId);
    }
  }

  initNewSearchRaw(): void {
    this.initFilterRaw();
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  initFilterRaw(): void {
    this.filterRaw.limit = 10;
    this.filterRaw.offset = 0;
    this.filterRaw.dir = 'desc';
  }

  getIntegration(id: number): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.integrationsService.getIntegration(id, null).subscribe(
      (data) => {
        if (data.success) {
          this.selectedIntegrationId = data.entity.id;
          this.integrationType = data.entity.importType;
          this.integration = data.entity;
          this.selectedIntegrationName = data.entity.name;
          const dataToSend: any = {};
          dataToSend.data = {
            integrationName: this.selectedIntegrationName,
            id: this.selectedIntegrationId,
          };
          dataToSend.toComponent = 'bread-crumbs';
          this.globalEventService.sendGlobalEvent(dataToSend);
          this.getLoads(false);
        }
      },
      () => {
        this.router.navigate(['integrations']);
      }
    );
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('IMPORT');
  }

  getAllCustomers(): void {
    this.integrationsService.getAllCustomers().subscribe((data) => {
      if (data.success) {
        this.customers = data.entity;
        this.showSearch = true;
        this.customers = data.entity.map((p) => ({
          value: p,
        }));
        this.searchFilters.filters[2].choices = this.customers;
      }
    });
  }

  getLoads(concat: boolean): void {
    this.isLoading = true;
    if (concat) {
      this.filter.offset += this.limit;
    }
    this.integrationsService
      .getLoads(this.selectedIntegrationId, this.filter)
      .subscribe(
        (data) => {
          if (concat) {
            this.loads = this.loads.concat(data.entity);
          } else {
            this.loads = data.entity;
          }
          this.setSelectedLoad();
          this.lengthToCompare = data.entity.length;
          this.newLengthEvent.emit(this.lengthToCompare);
          this.lockedTables = data.entity.length !== this.limit;
          this.lock = this.lockedTables;
          this.isLoading = false;
        },
        () => {
          this.lockedTables = false;
          this.lock = false;
          this.isLoading = false;
          this.toastr.error('Error occured!');
        }
      );
  }

  showLoad(e: any, load: any): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedLoad = load;
    this.processingActivityLogService
      .getProcessingLogs({ stageLoadId: this.selectedLoad.id })
      .subscribe((logs) => {
        this.processingLogs = logs.entity.aaData;
      });
  }

  isShownLoad(): boolean {
    if (this.selectedLoad !== undefined) {
      return true;
    }
    return false;
  }

  startImport(id: number): void {
    this.integrationsService.startImport(id).subscribe(
      (data) => {
        this.responseHelperService.success('Imported started ', false);
        this.initFilter();
        this.getLoads(false);
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  retryImport(id: number): void {
    this.integrationsService.retryImport(id).subscribe(
      (data) => {
        this.responseHelperService.success(
          'Staged load marked for Retry',
          false
        );
        this.initFilter();
        this.getLoads(false);
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  startProcessing(id: number): void {
    this.processingActivityLogService.startProcessing(id).subscribe(
      (data) => {
        this.responseHelperService.success('Processing started', false);
        this.initFilter();
        this.getLoads(false);
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  setSelectedLoad(): void  {
    if (this.selectedLoad?.id) {
      this.loads.forEach((load) => {
        if (load.id.toString() === this.selectedLoad.id.toString()) {
          this.selectedLoad = load;
        }
      });
    }
  }
}
